var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('h1',{staticClass:"form-title my-0"},[_vm._v("Roles")])]),_c('v-col',{attrs:{"md":"2"}},[_c('v-btn',{staticClass:"mx-2 custom-bold-button float-right",attrs:{"disabled":_vm.dataLoading,"color":"cyan white--text"},on:{"click":function($event){return _vm.updateDialogConfirm({}, 'Add')}}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v("mdi-plus")]),_vm._v(" Create ")],1)],1),_c('v-col',{attrs:{"md":"3"}},[_c('v-text-field',{attrs:{"clearable":"","dense":"","filled":"","flat":"","label":"Search","hide-details":"","solo":"","disabled":_vm.dataLoading,"loading":_vm.dataLoading,"color":"cyan"},on:{"click:clear":function($event){return _vm.clearFilter('search')},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getRows.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }return _vm.getRows.apply(null, arguments)}]},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}})],1),_c('v-col',{attrs:{"md":"1"}},[_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2 custom-bold-button white--text",attrs:{"color":"cyan","tile":"","disabled":_vm.dataLoading,"loading":_vm.dataLoading},on:{"click":_vm.getRows}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-magnify")])],1)]}}])},[_c('span',[_vm._v("Search")])])],1)],1)],1),_c('v-container',[_c('ListingTable',{attrs:{"columnCount":3,"dataLoading":_vm.dataLoading,"rowData":_vm.rowData},on:{"update:dataLoading":function($event){_vm.dataLoading=$event},"update:data-loading":function($event){_vm.dataLoading=$event},"update:rowData":function($event){_vm.rowData=$event},"update:row-data":function($event){_vm.rowData=$event}},scopedSlots:_vm._u([{key:"thead",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"simple-table-th",attrs:{"width":"200"}},[_vm._v("#")]),_c('th',{staticClass:"simple-table-th"},[_vm._v("Name")]),_c('th',{staticClass:"simple-table-th",attrs:{"width":"300"}},[_vm._v("Action")])])])]},proxy:true},{key:"tbody",fn:function(){return [_c('tbody',{staticClass:"custom-border-bottom custom-border-top"},[(_vm.rowData.length > 0)?_vm._l((_vm.rowData),function(row,index){return _c('tr',{key:index},[_c('td',{staticClass:"simple-table-td",attrs:{"link":""},on:{"click":function($event){return _vm.updateDialogConfirm(row, 'Update')}}},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('td',{staticClass:"simple-table-td",attrs:{"link":""},on:{"click":function($event){return _vm.updateDialogConfirm(row, 'Update')}}},[_vm._v(" "+_vm._s(row.name)+" ")]),_c('td',{staticClass:"simple-table-td"},[_c('v-btn',{staticClass:"mx-2 custom-bold-button",attrs:{"link":"","disabled":_vm.dataLoading,"color":"cyan white--text"},on:{"click":function($event){_vm.$router.push(
                      _vm.getDefaultRoute('profile.userpermission', {
                        params: {
                          id: row.id,
                        },
                        query: { name: row.name },
                      })
                    )}}},[_vm._v(" Permission ")])],1)])}):(!_vm.dataLoading)?_c('tr',[_c('td',{staticClass:"py-5",attrs:{"colspan":"5"}},[_c('p',{staticClass:"m-0 row-not-found text-center font-weight-500 font-size-16"},[_c('img',{staticClass:"row-not-found-image",attrs:{"src":_vm.$assetURL('media/error/empty.png')}}),_vm._v(" Uhh... There are no roles at the moment. ")])])]):_vm._e()],2)]},proxy:true}])})],1),_c('Dialog',{attrs:{"commonDialog":_vm.updateDialog},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.rolestype)+" Role ")]},proxy:true},{key:"body",fn:function(){return [_c('v-form',{ref:"userAddUpdateRoleForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.updateRoleRecords.apply(null, arguments)}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('RolesDetail',{attrs:{"rolesDetail":_vm.rolesDetail},on:{"update:rolesDetail":function($event){_vm.rolesDetail=$event},"update:roles-detail":function($event){_vm.rolesDetail=$event},"updateRolesDetail":_vm.updateUsersRolesDetail}})],1)]},proxy:true},{key:"action",fn:function(){return [_c('v-btn',{staticClass:"mx-2 custom-bold-button white--text",attrs:{"disabled":!_vm.formValid || _vm.formLoading,"loading":_vm.formLoading,"color":"cyan"},on:{"click":_vm.updateRoleRecords}},[_vm._v(" "+_vm._s(_vm.rolestype)+" Role ")]),_c('v-btn',{staticClass:"mx-2 custom-grey-border custom-bold-button",on:{"click":function($event){_vm.updateDialog = false}}},[_vm._v(" Close ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }